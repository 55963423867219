<template>
  <b-card>
    <div
      v-if="isLoading === true"
      class="text-center mb-2"
    >
      <b-spinner
        variant="primary"
        label="Text Centered"
      />
    </div>
    <b-form-group
      v-if="!isLoading"
      label="Fournisseur"
      label-for="fournisseur"
    >
      <v-select
        v-model="supplier"
        :clearable="false"
        placeholder="fournisseur"
        label="username"
        :options="suppliers"
      />
    </b-form-group>
    <b-form-group>
      <b-button
        variant="gradient-primary"
        class="d-inline-block"
        :class="{ 'disabled-cursor': isLoading || !supplier }"
        :disabled="isLoading || !supplier"
        @click="generateInvoice"
      >
        <feather-icon icon="PlusIcon" />
        <span class="ml-1">Générer une facture</span>
      </b-button>
      <b-alert
        v-model="alertVisible"
        :show="alertVisible"
        :variant="alertVariant"
        dismissible
        class="mt-3"
      >
        {{ alertMessage }}
      </b-alert>
    </b-form-group>
  </b-card>
</template>

<script>
import vSelect from 'vue-select'
import {
  BCard, BFormGroup, BButton,
} from 'bootstrap-vue'
import axios from 'axios'
import storeAuth from '@/store/store'

export default {
  name: 'CreateInvoice',
  components: {
    vSelect,
    BCard,
    BFormGroup,
    BButton,
  },
  data() {
    return {
      supplier: '',
      suppliers: [],
      orders: [],
      user: {},
      isLoading: false,
      alertVisible: false,
      alertVariant: '',
      alertMessage: '',
    }
  },
  created() {
    this.user = storeAuth.state.user
    this.getSuppliers()
  },
  methods: {
    async generateInvoice() {
      try {
        // Make API request to check if invoice can be created
        const response = await axios.get(`/api/invoices/check-invoice/${this.supplier.id}/`)

        // Check the response status
        if (response.status === 200) {
          // Navigate to invoice preview
          this.$router.push(`/invoices/preview/${this.supplier.id}`)
        } else {
          // Show alert if invoice cannot be created
          this.alertVariant = 'danger'
          this.alertMessage = response.data.message
          if (response.data.unvalidated_payment_ids && Array.isArray(response.data.unvalidated_payment_ids) && response.data.unvalidated_payment_ids.length > 0) {
            this.alertMessage += ` Les paiements qui doivent être validés: ${response.data.unvalidated_payment_ids.join(', ')}`
          }
          this.alertVisible = true
        }
      } catch (error) {
        // Show alert for any other errors
        console.error('Error generating invoice:', error)
        this.alertVariant = 'danger'
        if (error.response && error.response.data) {
          this.alertMessage = error.response.data.message
          if (Array.isArray(error.response.data.unvalidated_payment_ids) && error.response.data.unvalidated_payment_ids.length > 0) {
            this.alertMessage += ` Les paiements qui doivent être validés: ${error.response.data.unvalidated_payment_ids.join(', ')}`
          }
        } else {
          this.alertMessage = 'An error occurred while generating the invoice.'
        }
        this.alertVisible = true
      }
    },

    withTitle() {
      this.$router.push(`/invoices/preview/${this.supplier.id}`)
    },
    async getSuppliers() {
      this.isLoading = true
      const { data } = await axios.get('api/auth/list_suppliers_invoice/')
      this.suppliers = data
      this.isLoading = false
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
